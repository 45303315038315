import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/enrol',
    name: 'Enrol',
    component: () => import("@/views/Enrol.vue")
  },
  {
    path: '/uniform-options',
    name: 'Uniform Options',
    component: () => import("@/views/UniformOption.vue")
  },
  {
    path: '/about',
    name: 'About Us',
    component: () => import("@/views/AboutUs.vue")
  },
  {
    path: '/clothing',
    name: 'Clothing',
    component: () => import("@/views/Uniform.vue")
  },
  {
    path: '/enrolment-terms-and-conditions',
    name: 'Enrolment Terms and Conditions',
    component: () => import("@/views/TermsEnrolment.vue")
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import("@/views/PrivacyPolicy.vue")
  },
  {
    path: '/terms-use',
    name: 'Terms of Use',
    component: () => import("@/views/Terms.vue")
  },
  {
    path: '/schools-and-fees',
    name: 'Schools and Fees',
    component: () => import("@/views/SchoolsAndFees.vue")
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import("@/views/ContactUs.vue")
  },
  {
    path: '/cookies-policy',
    name: 'Cookies Policy',
    component: () => import("@/views/CookiesPolicy.vue")
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
